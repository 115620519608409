import axios, { AxiosResponse } from 'axios';
import {
  BookingViewModel,
  ChatViewModel,
  CommentViewModel,
  FileResponseViewModel,
  MediaFileReportViewModel,
  UserViewModel,
  UserAccountViewModel,
  CategoryViewModel,
  CancelBookingOnBehalfOfProviderRequest,
  BookingResponseTimeViewModel,
  ServiceProviderPayoutLogViewModel,
  UserPaymentLogViewModel,
  ExperienceLogViewModel,
  BannerViewModel,
  ProviderSummaryResponse,
  CustomerSummaryViewModel,
  ExperienceLogViewModelV2,
  ProviderBookingPayoutSummaryViewModel,
  AdminCommentViewModel,
  DiscountCodeViewModel,
  CreatePromotionCodeInput,
  VendorViewModel,
  GigViewModel,
  GetGigsSearchRequest,
  UserRedemptionCodeViewModel,
  GetExperienceLogsResponse,
  GetAllEventsOnlyResponse,
  GigInvitationCodeViewModel,
  GigInvitationCodeRequest,
  GetAllGigTicketClicksResponse,
  BannerType
} from './backend/models';
import { AdminCommentType, BookingType, SortType } from './enums';
import getGlobal from './globals';
import { AdminNoteModel } from './types';

const _axios = axios.create({
  withCredentials: true,
  baseURL: getGlobal('api')
});

export function getPublicFile(fileName: string) {
  if (!fileName) return;
  return `${getGlobal(
    'api'
  )}/PublicFile/DownloadPublicFile?filename=${fileName}`;
}

//https://hoopla-bucket.s3.us-west-2.amazonaws.com/
export function getMediaFile(filename: string) {
  return filename ? `https://d1p2nq1n6y6s4p.cloudfront.net/${filename}` : null;
}

export async function signIn(email: string, password: string) {
  const response: AxiosResponse<UserViewModel> = await _axios.post(
    '/Admin/LogIn',
    {
      email,
      password
    },
    {
      headers: {
        'Content-Type': 'application/json',
        ...getGlobal('devHeader')
      }
    }
  );

  return response.data;
}

export async function logOut() {
  await _axios.get('/Admin/LogOut', {
    headers: {
      ...getGlobal('devHeader')
    }
  });
}

export async function getUser() {
  const response: AxiosResponse<UserViewModel> = await _axios.get(
    '/Accounts/Info/Own'
  );

  return response.data;
}

export async function updateUser(newUserData: FormData) {
  const response: AxiosResponse<UserViewModel> = await _axios.put(
    '/Admin/UpdateUserProfile',
    newUserData
  );

  return response.data;
}

export async function deleteUser(userId: string) {
  await _axios.delete(`/Admin/DeleteUserProfile?userId=${userId}`);
}

export async function getUsers(skip: number = 0, take: number = 100) {
  const response: AxiosResponse<UserViewModel[]> = await _axios.get(
    `/Admin/GetUsers?skip=${skip}&take=${take}`
  );

  return response.data;
}

export async function searchUser(query: string) {
  const response: AxiosResponse<UserViewModel[]> = await _axios.get(
    `/Admin/GetUsers/search?searchName=${query}`
  );

  return response.data;
}

export async function getCustomerStats(userId: string) {
  const response: AxiosResponse<CustomerSummaryViewModel> = await _axios.get(
    `/UserStatistics/GetCustomerSummary?userId=${userId}`
  );

  return response.data;
}

export async function getProviderStats(userId: string) {
  const response: AxiosResponse<ProviderSummaryResponse> = await _axios.get(
    `/UserStatistics/GetProviderSummary?userId=${userId}`
  );

  return response.data;
}

export async function setUserScore(userId: string, score: number) {
  const response: AxiosResponse<UserViewModel> = await _axios.put(
    `/Admin/UpdateUserScoring?userId=${userId}&value=${score}`
  );

  return response.data;
}

export async function getAdminNotesByType(
  commentType: AdminCommentType,
  targetId: string | number
) {
  const response: AxiosResponse<AdminCommentViewModel[]> = await _axios.get(
    `/Admin/GetAdminComment?adminCommentType=${commentType}&id=${targetId}`
  );

  return response.data;
}

export async function addAdminNote(adminNote: AdminNoteModel) {
  const response: AxiosResponse<AdminCommentViewModel> = await _axios.post(
    '/Admin/AddAdminComment',
    adminNote
  );

  return response.data;
}

export async function deleteAdminNoteByType(
  commentType: AdminCommentType,
  targetId: string | number
) {
  const response: AxiosResponse = await _axios.delete(
    `/Admin/DeleteAdminComment?adminCommentType=${commentType}&id=${targetId}`
  );

  return response.data;
}

export async function saveUserComments(userId: string, notes: string) {
  const response: AxiosResponse<UserViewModel> = await _axios.put(
    `/Admin/UpdateUserAdminNotes?userId=${userId}&notes=${encodeURIComponent(
      notes
    )}`
  );

  return response.data;
}

export async function changeUserVisibility(userId: string, visible: boolean) {
  const response: AxiosResponse<UserViewModel> = await _axios.put(
    `/Admin/UpdateUserVisibility?userId=${userId}&visibility=${visible}`
  );

  return response.data;
}

export async function grantRestrictUserAccess(
  userId: string,
  restrictAccess: boolean
) {
  const response: AxiosResponse<UserViewModel> = await _axios.put(
    `/Admin/UpdateUserRestrictAccess?userId=${userId}&restrictAccess=${restrictAccess}`
  );

  return response.data;
}

export async function changeUserPassword(userId: string, newPassword: string) {
  const response = await _axios.put(
    `/Admin/UpdateUserPassword?userId=${userId}&newPwd=${newPassword}`
  );

  return response.data;
}

export async function resetUserPassword(email: string) {
  const response: AxiosResponse<string> = await _axios.get(
    `/Auth/GetPasswordResetToken?email=${email}`
  );

  return response.data;
}

export async function getMediaFileReports(skip: number, take: number) {
  const response: AxiosResponse<MediaFileReportViewModel[]> = await _axios.get(
    `/Admin/GetMediaFileReports?skip=${skip}&take=${take}`
  );

  return response.data;
}

export async function resolveMediaFileReport(
  userId: string,
  mediaFileId: number,
  adminMessage: string = '',
  isBadVideo: boolean
) {
  const response: AxiosResponse<MediaFileReportViewModel[]> = await _axios.post(
    `/Admin/ResolveMediaFileReport?userId=${userId}&mediaFileId=${mediaFileId}&isBadVideo=${isBadVideo}&adminMessage=${adminMessage}`
  );
  return response.data;
}

export async function getBookingReports(skip: number, take: number) {
  const response: AxiosResponse<BookingViewModel[]> = await _axios.get(
    `/Admin/GetBookingReports?skip=${skip}&take=${take}`
  );

  return response.data;
}

export async function resolveBookingReport(
  bookingId: number,
  decisionId: number,
  adminMessage: string = ''
) {
  const response: AxiosResponse<BookingViewModel> = await _axios.post(
    `/Admin/ResolveBookingReport?bookingId=${bookingId}&decisionId=${decisionId}&adminMessage=${adminMessage}`
  );

  return response.data;
}

export async function removeVideo(mediaFileId: number, adminMessage: string) {
  const response: AxiosResponse<MediaFileReportViewModel> = await _axios.post(
    `/Admin/RemoveVideo?mediaFileId=${mediaFileId}&adminMessage=${adminMessage}`
  );

  return response.data;
}

export async function getVideos(skip: number, take: number) {
  const response: AxiosResponse<FileResponseViewModel[]> = await _axios.get(
    `/Content/Videos?skip=${skip}&take=${take}`
  );

  return response.data;
}

export async function updateMediaFileStatus(
  mediaFileId: number,
  status: number,
  rejectReason?: number,
  additionalInformation?: string
) {
  const additionalInformationParam = !!additionalInformation
    ? `&additionalInformation=${additionalInformation}`
    : '';

  const rejectReasonParam = !!rejectReason
    ? `&rejectReasonType=${rejectReason}`
    : '';

  const response: AxiosResponse = await _axios.put(
    `/Admin/UpdateVideoStatus?mediaFileId=${mediaFileId}&videoStatusType=${status}${additionalInformationParam}${rejectReasonParam}`
  );

  return response.data;
}

export async function saveVideoNotes(mediaFileId: number, notes: string) {
  const response: AxiosResponse = await _axios.put(
    `/Admin/UpdateVideoAdminComments?mediaFileId=${mediaFileId}&comments=${encodeURIComponent(
      notes
    )}`
  );

  return response.data;
}

export async function getVideoComments(
  mediaFileId: number,
  skip: number,
  take: number
) {
  const paging =
    skip !== null && take !== null ? `&skip=${skip}&take=${take}` : '';

  const response: AxiosResponse<CommentViewModel[]> = await _axios.get(
    `/ContentInteraction/GetMediaFileComments?mediaFileId=${mediaFileId}${paging}`
  );

  return response.data;
}

export async function getAllComments(
  searchQuery: string | null,
  skip: number = 0,
  take: number = 50,
  sort: SortType = SortType.Desc
) {
  const paramString = `${
    searchQuery ? 'search=' + searchQuery + '&' : ''
  }skip=${skip}&take=${take}&sort=${sort === SortType.Asc ? 1 : 2}`;

  const response: AxiosResponse<CommentViewModel[]> = await _axios.get(
    `/Admin/GetAllComments?${paramString}`
  );

  return response.data;
}

//Chat
export async function createDirectChat(userId: string) {
  const response: AxiosResponse<ChatViewModel> = await _axios.get(
    `/Admin/CreateDirectChat?userId=${userId}`
  );

  return response.data;
}

export async function getBookingChat(bookingId: number) {
  const response: AxiosResponse<ChatViewModel> = await _axios.get(
    `/Messenger/GetBookingChat?bookingId=${bookingId}&includeBookingEventMessages=false`
  );

  return response.data;
}

export async function sendMessageToChat(chatId: string, message: string) {
  const response: AxiosResponse<ChatViewModel> = await axios.post(
    `${getGlobal('api')}/Admin/SendMessageToChat`,
    {
      chatId,
      messageBody: message
    },
    {
      withCredentials: true
    }
  );

  return response.data;
}

//Banners
export async function getAllBanners() {
  const response: AxiosResponse<BannerViewModel[]> = await _axios.get(
    '/Banners/GetAllBanners'
  );

  return response.data;
}

export async function createBanner(
  mediaFile: File,
  link: string,
  type: BannerType = BannerType.NUMBER_0,
  genericData?: string
) {
  const formData = new FormData();
  formData.append('MediaFile', mediaFile);
  formData.append('Link', link);
  formData.append('BannerType', type.valueOf().toString());
  formData.append('GenericData', genericData || '');

  const response: AxiosResponse<BannerViewModel> = await _axios.post(
    '/Banners/AddBanner',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export async function updateBanner(
  bannerId: number,
  link: string,
  mediaFile?: File,
  type: BannerType = BannerType.NUMBER_0,
  genericData?: string
) {
  const formData = new FormData();
  formData.append('Link', link);
  formData.append('BannerType', type.valueOf().toString());
  formData.append('GenericData', genericData || '');

  if (mediaFile) formData.append('MediaFile', mediaFile);

  const response: AxiosResponse<BannerViewModel> = await _axios.put(
    `/Banners/UpdateBanner?bannerId=${bannerId}`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export function deleteBanner(bannerId: number) {
  return _axios.delete(`/Banners/DeleteBanner?bannerPublicId=${bannerId}`);
}

//Users
export async function createRegistration(data: object) {
  const response: AxiosResponse<UserAccountViewModel> = await _axios.post(
    `/Admin/CreateRegistration`,
    data
  );

  return response.data;
}

export async function getUserComments(
  userId: string,
  skip: number,
  take: number
) {
  const response: AxiosResponse<CommentViewModel[]> = await _axios.get(
    `/Admin/Moderation/Comments/GetLatestsCommentsFromUser?userId=${userId}&skip=${skip}&take=${take}`
  );

  return response.data;
}

export async function deleteComment(commentId: number) {
  const response: AxiosResponse = await _axios.delete(
    `/Admin/Moderation/Comments/DeleteComment?commentId=${commentId}`
  );

  return response.data;
}

//Invitation Codes
export async function getAllInvitationCodes(eventId: number) {
  const response: AxiosResponse<GigInvitationCodeViewModel[]> =
    await _axios.get('/GigInvitationCode/GetAll', {
      params: { gigId: eventId }
    });

  return response.data;
}

//Create Invitation Codes
export async function createInvitationCode(data: object) {
  const response: AxiosResponse<GigInvitationCodeViewModel> = await _axios.post(
    '/GigInvitationCode/Create',
    data
  );

  return [response.data, response.status];
}

//Categories
export async function getAllCategories() {
  const response: AxiosResponse<CategoryViewModel[]> = await _axios.get(
    '/Categories/GetAllCategories'
  );

  return response.data;
}

export async function createCategory(mediaFile: File, name: string) {
  const formData = new FormData();
  formData.append('File', mediaFile);
  formData.append('Name', name);

  const response: AxiosResponse<CategoryViewModel> = await _axios.post(
    '/Categories/Create',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export async function updateCategory(
  categoryId: number,
  name: string,
  mediaFile?: File
) {
  const formData = new FormData();
  formData.append('CategoryId', categoryId.toString());
  formData.append('Name', name);

  if (mediaFile) formData.append('File', mediaFile);

  const response: AxiosResponse<CategoryViewModel> = await _axios.put(
    '/Categories/Update',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export async function deleteCategory(categoryId: number) {
  const response: AxiosResponse<CategoryViewModel> = await _axios.delete(
    `/Categories/Delete?categoryId=${categoryId}`
  );

  return response.data;
}

//Bookings
export async function getBookingsResponseTime(
  bookingType: BookingType,
  searchQuery: string,
  sort: SortType,
  skip: number,
  take: number
) {
  let queryParams = `experienceType=${bookingType}`;
  queryParams += searchQuery ? `&query=${searchQuery}` : '';
  queryParams += searchQuery ? `&sortBy=${sort === SortType.Desc}` : '';
  queryParams += `&skip=${skip}&take=${take}`;

  const response: AxiosResponse<BookingResponseTimeViewModel[]> =
    await _axios.get(`/Admin/Moderation/Bookings/ResponseTime?${queryParams}`);

  return response.data;
}

export async function justifyBooking(
  payload: CancelBookingOnBehalfOfProviderRequest
) {
  const response: AxiosResponse<BookingViewModel> = await _axios.post(
    `/Admin/CancelBookingOnBehalfOfProvider`,
    payload
  );

  return response.data;
}

export async function saveBookingComments(bookingId: number, comment: string) {
  const response: AxiosResponse = await _axios.put(
    `/Admin/AddOrUpdateComment?bookingId=${bookingId}&comment=${encodeURIComponent(
      comment
    )}`
  );

  return response.data;
}

export async function getBookingLogs() {
  const response: AxiosResponse<ExperienceLogViewModel[]> = await _axios.get(
    '/Admin/GetExperienceLogs?skip=0&take=10000'
  );

  return response.data;
}

export async function getBookingLogsV2(
  searchQuery: string,
  skip: number,
  take: number
) {
  const queryParams =
    (searchQuery ? `searchText=${encodeURIComponent(searchQuery)}&` : '') +
    `skip=${skip}&take=${take}`;

  const response: AxiosResponse<GetExperienceLogsResponse[]> = await _axios.get(
    `/Admin/v2/GetExperienceLogs?${queryParams}`
  );

  return response.data;
}

export async function getBooking(bookingId: number) {
  const response: AxiosResponse<BookingViewModel> = await _axios.get(
    `/Bookings/${bookingId}`
  );

  return response.data;
}

export async function getExperience(experienceId: number) {
  const response: AxiosResponse<GigViewModel> = await _axios.get(
    `/Gigs/${experienceId}`
  );

  return response.data;
}

export async function getExperiences(skip: number, take: number) {
  const response: AxiosResponse<GetAllEventsOnlyResponse[]> = await _axios.get(
    `/Admin/GetAllEventsOnly`,
    {
      params: { skip, take }
    }
  );

  return response.data;
}

export async function updateEvent(eventId: number, hashtags: string[]) {
  const response: AxiosResponse = await _axios.put(
    '/Admin/EditEvent',
    {
      id: eventId,
      hashtags
    },
    {
      headers: {
        'api-version': '2'
      }
    }
  );

  return response.data;
}

export async function getExternalEventLinks(skip: number, take: number) {
  const response: AxiosResponse<GetAllGigTicketClicksResponse[]> =
    await _axios.get('/Admin/GetAllGigTicketClicks', {
      params: { skip, take }
    });

  return response.data;
}

export async function getBookingPayouts(bookingId: number) {
  const response: AxiosResponse<ProviderBookingPayoutSummaryViewModel> =
    await _axios.get(`/Admin/GetBookingPayout?bookingId=${bookingId}`);

  return response.data;
}

export async function getExperiencePayouts(
  fromDateMilis: number,
  toDateMilis: number
) {
  const response: AxiosResponse<ExperienceLogViewModelV2[]> = await _axios.get(
    `/Admin/GetExperiencePayoutLogs?fromDate=${fromDateMilis}&toDate=${toDateMilis}`
  );

  return response.data;
}

export async function getExcelEventInformation(eventId: number) {
  const response: AxiosResponse<Blob> = await _axios.get(
    '/Bookings/GetExcelEventInformation',
    {
      params: { eventId },
      responseType: 'blob'
    }
  );

  return response.data;
}

//Payouts
export async function getServiceProviderPayouts() {
  const response: AxiosResponse<ServiceProviderPayoutLogViewModel[]> =
    await _axios.get('/Admin/GetServiceProviderPayoutLogs');

  return response.data;
}

// User Payments
export async function getUserPayments() {
  const response: AxiosResponse<UserPaymentLogViewModel[]> = await _axios.get(
    '/Admin/GetUserPaymentLogs'
  );

  return response.data;
}

//Discount codes
export async function getDiscountCodes() {
  const response: AxiosResponse<DiscountCodeViewModel[]> = await _axios.get(
    '/Admin/DiscountCodes/List'
  );

  return response.data;
}

export async function getDiscountCode(id: number) {
  const response: AxiosResponse<DiscountCodeViewModel> = await _axios.get(
    `/Admin/DiscountCodes/${id}`
  );

  return response.data;
}

export async function getCouponsReport(
  skip: number,
  take: number,
  query?: string
) {
  const queryString = `?skip=${skip}&take=${take}${
    query ? '&query=' + query : ''
  }`;

  const response: AxiosResponse<UserRedemptionCodeViewModel[]> =
    await _axios.get(`/Admin/GetUserRedemptionCodes${queryString}`);

  return response.data;
}

export async function updateDiscountCode(id: number, notes: string) {
  const response: AxiosResponse<DiscountCodeViewModel> = await _axios.put(
    `/Admin/DiscountCodes/EditComment?id=${id}&newComment=${notes}`
  );

  return response.data;
}

export async function createDiscountCode(
  discountCode: CreatePromotionCodeInput
) {
  const response: AxiosResponse<DiscountCodeViewModel> = await _axios.post(
    '/Admin/DiscountCodes/Create',
    discountCode
  );

  return response.data;
}

export async function toggleDiscountCode(id: number) {
  const response: AxiosResponse<DiscountCodeViewModel> = await _axios.put(
    `/Admin/DiscountCodes/ToggleActive?id=${id}`
  );

  return response.data;
}

// Search
export async function searchCreators(query: string) {
  const response: AxiosResponse<VendorViewModel[]> = await _axios.get(
    `/Roles/GetAllCreatorPROs/search?textName=${query}`
  );

  return response.data;
}

export async function searchExperiences(payload: GetGigsSearchRequest) {
  const response: AxiosResponse<GigViewModel[]> = await _axios.put(
    '/Gigs/Search',
    payload
  );

  return response.data;
}

//Hashtags
export async function getAllHashtags() {
  const response: AxiosResponse<string[]> = await _axios.get('/Hashtags/list');

  return response.data;
}
