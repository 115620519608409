import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ActionsToolbar from '../../components/ActionsToolbar/ActionsToolbar';
import Loader from '../../components/Loader/Loader';
import NewBannerModal from '../../components/Modals/NewBannerModal/NewBannerModal';
import BannerRow from '../../components/TableRows/BannerRow/BannerRow';
import BannerTableHeader from '../../components/TableHeaders/BannerTableHeader/BannerTableHeader';
import {
  createBanner,
  deleteBanner,
  getAllBanners,
  updateBanner
} from '../../core/api';
import { BannerType, BannerViewModel } from '../../core/backend/models';
import './Banners.scss';
import BaseModal from '../../components/Modals/BaseModal/BaseModal';
import EditBannerModal from '../../components/Modals/EditBannerModal/EditBannerModal';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';

const Banners = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [reportPage, setReportPage] = useState(0);
  const [isNewBannerOpen, setIsNewBannerOpen] = useState(false);
  const [editModal, setEditModal] = useState<{
    banner: BannerViewModel | null;
    isOpen: boolean;
  }>({ banner: null, isOpen: false });
  const [deleteModal, setDeleteModal] = useState<{
    id: number | null;
    isOpen: boolean;
  }>({ id: null, isOpen: false });
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [banners, setBanners] = useState<BannerViewModel[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  /**
   * Request content
   */
  useEffect(() => {
    getContent().then(() => setIsLoading(false));
  }, []);

  const getContent = async () => {
    const _banners = await getAllBanners();
    setBanners(_banners);
  };

  /**
   * Table methods
   */

  const handleChangePage = (event: unknown, newPage: number) => {
    setReportPage(newPage);
  };

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const idFromItem = (item: BannerViewModel) => {
    return item.id;
  };

  /**
   * Actions
   */

  const handleCreateNewBanner = async (values: {
    image: File;
    link: string;
    type: BannerType;
    genericData?: string;
  }) => {
    try {
      setIsProcessing(true);
      const newBanner = await createBanner(
        values.image,
        values.link,
        values.type,
        values.genericData
      );
      setBanners([newBanner, ...banners]);
    } catch (e: any) {
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
      closeNewBannerModal();
    }
  };

  const handleEditBanner = async (values: {
    image: File;
    link: string;
    type: BannerType;
    genericData?: string;
  }) => {
    try {
      setIsProcessing(true);

      const updatedBanner = await updateBanner(
        editModal.banner.id,
        values.link,
        values.image,
        values.type,
        values.genericData
      );

      const _banners = banners.map((banner) =>
        banner.id === updatedBanner.id ? updatedBanner : banner
      );

      setBanners(_banners);
    } catch (e: any) {
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
      handleCloseEditModal();
    }
  };

  const handleDeleteBanner = async () => {
    try {
      setIsProcessing(true);
      await deleteBanner(deleteModal.id);
      const _banners = banners.filter((banner) => banner.id !== deleteModal.id);
      setBanners(_banners);
    } catch (e: any) {
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
      handleCloseDeleteModal();
    }
  };

  const deleteSelectedBanners = async () => {
    const _deleteBanners = selectedItems.map((id) => deleteBanner(id));
    Promise.all(_deleteBanners);

    const _newBanners = banners.filter(
      (banner) => !selectedItems.includes(banner.id)
    );

    setBanners(_newBanners);
    setSelectedItems([]);

    handleCloseDeleteModal();
  };

  /**
   * Modal Methods
   */
  const handleShowNewBannerModal = () => {
    setIsNewBannerOpen(true);
  };

  const closeNewBannerModal = () => {
    setIsNewBannerOpen(false);
  };

  const handleShowEditBanner = (banner: BannerViewModel) => () => {
    setEditModal({ banner, isOpen: true });
  };

  const handleCloseEditModal = () => {
    setEditModal({ banner: null, isOpen: false });
  };

  const handleShowDeleteBanner = (bannerId: number | null) => () => {
    setDeleteModal({ id: bannerId, isOpen: true });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal({ id: null, isOpen: false });
  };

  const handleCloseErrorModal = () => {
    setErrorModalVisible(false);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='Banners'>
        <div className='Banners__actionButtons'>
          <Button
            variant='text'
            color='secondary'
            startIcon={<AddIcon />}
            onClick={handleShowNewBannerModal}
          >
            Add New Banner
          </Button>
        </div>
        <div className='Banners__content'>
          <BasicTable
            Header={BannerTableHeader}
            data={banners}
            idFromItem={idFromItem}
            page={reportPage}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            renderItem={(banner, index, { isSelected, handleSelectRow }) => (
              <BannerRow
                key={banner.id}
                banner={banner}
                selected={isSelected}
                onSelect={handleSelectRow(banner.id)}
                onEdit={handleShowEditBanner(banner)}
                onDelete={handleShowDeleteBanner(banner.id)}
              />
            )}
          />
        </div>
      </div>

      {selectedItems.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems.length}
          onDeclineAll={handleShowDeleteBanner(null)}
          declineTitle='Delete'
        />
      )}

      <NewBannerModal
        isOpen={isNewBannerOpen}
        onClose={closeNewBannerModal}
        isProcessing={isProcessing}
        onAccept={handleCreateNewBanner}
      />

      <EditBannerModal
        isOpen={editModal.isOpen}
        banner={editModal.banner}
        onClose={handleCloseEditModal}
        isProcessing={isProcessing}
        onAccept={handleEditBanner}
      />

      <BaseModal
        title='Delete Banner'
        isOpen={deleteModal.isOpen}
        isProcessing={isProcessing}
        onAccept={deleteModal.id ? handleDeleteBanner : deleteSelectedBanners}
        onClose={handleCloseDeleteModal}
      >
        Are you sure you want to delete the banner(s)? This action can not be
        undone.
      </BaseModal>

      <ErrorModal
        isOpen={errorModalVisible}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseErrorModal}
      />
    </>
  );
};

export default Banners;
